<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="编辑" name="edit"><BillEditForm ref="billEditForm" :params="params" @done="handleEditDone" /></el-tab-pane>
            <el-tab-pane label="租金" name="detail"><BillDetail :params="params" @change="handleRentChange" /></el-tab-pane>
            <el-tab-pane label="罚金" name="fine"><BillFine :params="params" @change="handleFineChange" /></el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import BillEditForm from "./BillEditForm"
import BillDetail from "./BillDetail"
import BillFine from "./BillFine"

export default {
    name: 'CostBillEdit',
    components: {
        BillEditForm,
        BillDetail,
        BillFine
    },
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            activeName: "edit",
        }
    },
    created() {

    },
    methods: {
        // handleClick(tab, event) {
        handleClick() {

        },
        handleEditDone() {
            this.$emit('done');
        },
        handleRentChange(data) {
            this.$refs.billEditForm.updateRent(data);
        },
        handleFineChange(data) {
            this.$refs.billEditForm.updateFine(data);
        },
    }
};
</script>

<style scoped>
.dialog-inner /deep/ .el-tabs__header {
    margin-bottom: 10px;
}
</style>