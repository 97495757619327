<template>
    <div class="dialog-inner" style="padding:0;">
        <el-row :gutter="20">
            <el-col :span="8">
                <div class="data-item">
                    <div class="label">项目名称：</div>
                    <div class="text">{{ params.projectName }}</div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="data-item">
                    <div class="label">分包单位名称：</div>
                    <div class="text">{{ params.supplierName }}</div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="data-item">
                    <div class="label">结算周期：</div>
                    <div class="text">{{ params.month }}</div>
                </div>
            </el-col>
        </el-row>
        <div class="datatable-inner">
            <div class="datatable">
                <el-table
                    ref="curBillTable"
                    :data="tableData"
                    v-loading="tableLoading"
                    element-loading-background="rgba(255, 255, 255, 0.3)"
                    border
                    style="width: 100%">
                    <el-table-column
                        label="序号"
                        align="center"
                        width="60">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="roomName"
                        label="宿舍编号"
                        min-width="100">
                    </el-table-column>
                    <el-table-column
                        prop="costTypeName"
                        label="费用类型"
                        min-width="100">
                    </el-table-column>
                    <el-table-column
                        prop="startDate"
                        label="开始日期"
                        min-width="110">
                    </el-table-column>
                    <el-table-column
                        prop="endDate"
                        label="结束日期"
                        min-width="110">
                    </el-table-column>
                    <el-table-column
                        prop="rent"
                        label="租金"
                        min-width="100">
                    </el-table-column>
                    <el-table-column
                        prop="rule"
                        label="费用规则"
                        min-width="120">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.rule" placeholder="费用规则" size="small" @change="updateTotalRent">
                                <el-option label="无效" :value="0"></el-option>
                                <el-option label="半月" :value="1"></el-option>
                                <el-option label="整月" :value="2"></el-option>
                                <el-option label="减免" :value="3"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="reason"
                        label="修改原因"
                        min-width="120">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.reason" placeholder="修改原因" size="small" @blur="updateTotalRent"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import BigDecimal from 'js-big-decimal'

export default {
    name: 'CostBillDetail',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            tableData: [],
        }
    },
    created() {
        this.init();
    },
    methods: {
        ...common,
        ...verify,
        init() {
            this.loadTable();
        },
        // 加载表格数据
        loadTable() {
            let params = {};
            params.id = this.params.id;
            this.tableLoading = true;
            http.get('v1/paid/detail/list', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    data.forEach(item => {
                        item.rent = this.toFixed(item.rent, 2);
                    });
                    this.tableData = data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        updateTotalRent() {
            let zero = new BigDecimal("0");
            let two = new BigDecimal("2");
            this.tableData.forEach(item => {
                let rent;
                let monthRent = new BigDecimal(item.monthRent);
                rent = zero;
                if (item.rule == 1) {
                    rent = monthRent.divide(two).round(2, BigDecimal.RoundingModes.CEILING);
                } else if (item.rule == 2) {
                    rent = monthRent.round(2, BigDecimal.RoundingModes.CEILING);
                }
                item.rent = rent.getValue();
            });

            this.$emit("change", this.tableData);
        },
    }
};
</script>

<style scoped>
</style>
