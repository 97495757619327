<template>
    <div class="dialog-inner" style="padding:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="项目名称：" class="mix-item">
                        {{ params.projectName }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="分包单位名称：" class="mix-item">
                        {{ params.supplierName }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="data-item">
                        <el-form-item label="月度账单单号：" class="mix-item">
                            {{ params.paidNo }}
                        </el-form-item>
                    </div>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="结算周期：" class="mix-item">
                        {{ params.month }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="租金：" class="mix-item">
                        {{ params.rent }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="罚款金额：" class="mix-item">
                        {{ params.fine }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="扣款金额：" class="mix-item">
                        {{ params.cutPayment }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="调差金额" prop="escalation">
                        <el-input type="text" v-model="form.escalation" size="small" maxlength="15" @blur="fieldTrim(form, 'escalation')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="支付状态" prop="isPaid">
                        <el-radio-group v-model="form.isPaid">
                            <el-radio :label="1">已支付</el-radio>
                            <el-radio :label="0">未支付</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="form.isPaid == 1">
                <el-col :span="12">
                    <el-form-item label="支付金额" prop="paidMoney">
                        <el-input type="text" v-model="form.paidMoney" readonly size="small" maxlength="15" @blur="fieldTrim(form, 'paidMoney')" class="input-readonly"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="支付日期" prop="paidTime">
                        <el-date-picker
                            v-model="form.paidTime"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="附件" prop="date">
                        <el-upload
                            :action="uploadUrl"
                            :on-remove="handleRemove"
                            :on-exceed="handleExceed"
                            :on-success="handleSuccess"
                            :on-error="handleError"
                            :on-change="handleChange"
                            multiple
                            :limit="5"
                            :file-list="initFileList">
                            <el-button size="small" type="primary">选择文件</el-button>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="form.remark" size="small" maxlength="500" @blur="fieldTrim(form, 'remark')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import BigDecimal from 'js-big-decimal'

export default {
    name: 'CostBillEditForm',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            supplierList: [],
            paymentTypeList: [
                {  id: 1, name:"银行转账" },
                {  id: 2, name:"现金" },
                {  id: 3, name:"网络支付" },
            ],
            form: {
                id: '',
                escalation: '',
                isPaid: 0,
                paidMoney: '',
                paidTime: '',
                remark: '',
                detailList: [],
                file: '',
            },
            rules: {
                escalation: [
                    {required: true, message: '调差金额不能为空', trigger: ['blur','change']},
                    {required: true, validator: this.checkMoney, trigger: ['blur','change']},
                ],
                paidMoney: [
                    {required: true, message: '支付金额不能为空', trigger: ['blur','change']},
                    {required: true, validator: this.checkMoney, trigger: ['blur','change']},
                ],
                paidTime: [{required: true, message: '支付日期不能为空', trigger: 'change'}],
            },
            submitting: false,
            initFileList: [],
            fileList: [],
            uploadUrl: http.BASE_URL + 'v1/upload',
        }
    },
    watch: {
        ['form.escalation']() {
            this.updatePaidMoney();
        },
    },
    created() {
        this.init();
    },
    methods: {
        ...common,
        ...verify,
        init() {
            this.form.id = this.params.id;
            this.form.escalation = this.params.escalation;
            this.form.isPaid = this.params.isPaid;
            this.form.paidMoney = this.params.paidMoney == '-' ? '' : this.params.paidMoney;
            this.form.paidTime = this.params.paidTime == '-' ? '' : this.params.paidTime;
            this.form.remark = this.params.remark;
            
            if (this.params.file) {
                let arr;
                try {
                    arr = JSON.parse(this.params.file);
                } catch (ex) {
                    arr = [];
                }
                this.initFileList = arr;
                this.fileList = arr;
            }

            this.form.file = this.params.file;
        },
        updatePaidMoney() {
            let rent = new BigDecimal(this.params.rent);
            let fine = new BigDecimal(this.params.fine);
            let cutPayment = new BigDecimal(this.params.cutPayment);
            let escalation = new BigDecimal(this.form.escalation);

            this.form.paidMoney = rent.add(fine)
                                    .add(cutPayment)
                                    .add(escalation)
                                    .round(2, BigDecimal.RoundingModes.CEILING)
                                    .getValue();
        },
        updateRent(data) {
            let totalRent = new BigDecimal("0");
            let detail = [];
            data.forEach(item => {
                let rent = new BigDecimal(item.rent);

                detail.push({
                    id: item.id,
                    rent: rent.getValue(),
                    rule: item.rule,
                    reason: item.reason,
                });

                totalRent = totalRent.add(rent);
            });
            this.params.rent = totalRent.getValue();
            this.updatePaidMoney();
            this.form.detailList = detail;
        },
        updateFine(data) {
            let totalFine = new BigDecimal("0");
            data.forEach(item => {
                let revenue = new BigDecimal(item.revenue);
                totalFine = totalFine.add(revenue);
            });
            this.params.fine = totalFine.getValue();
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {...this.form};
                    if (data.isPaid === 0) {
                        data.paidMoney = '';
                    }
                    http.post('v1/paid/edit', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('提交成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleRemove(file, fileList) {
            this.updateFileList(fileList);
        },
        handleSuccess(response, file, fileList) {
            let data = file.response;
            if (data.code != 0) {
                this.showError(data.msg);
                return;
            }
            this.updateFileList(fileList);
        },
        handleChange(file, fileList) {
            let domList = this.$el.querySelectorAll('.el-upload-list li .el-icon-close');
            let arr = [];
            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].response && fileList[i].response.code != 0) {
                    arr.push(domList[i]);
                }
            }
            for (let i = 0; i < arr.length; i++) {
                arr[i].dispatchEvent(new MouseEvent('click'));
            }
        },
        // handleError(err, file, fileList) {
        handleError(err, file) {
            this.showError(file.name + " 上传失败");
        },
        handleExceed() {
            this.showError("最多允许上传5个附件");
        },
        updateFileList(fileList) {
            let arr = [];
            fileList.forEach(file => {
                let { code, data } = file.response? file.response: {code:0,data:file};
                if (code == 0) {
                    arr.push({ name: data.originalName || data.name, url: data.url, });
                }
            });
            this.form.file = JSON.stringify(arr);
            this.fileList = arr;
        },
        handleDownLoad(ev) {
            let aDomList = this.$el.querySelectorAll('.el-upload-list li a');
            if (ev.target.tagName.toLowerCase() == 'a') {
                let i = -1;
                for (i = 0; i < aDomList.length; i++) {
                    if (aDomList[i] == ev.target) {
                        break;
                    }
                }
                if (i != -1 && i < this.fileList.length) {
                    let file = this.fileList[i];
                    let { data } = file.response? file.response: { data: file };
                    let filename = data.originalName || data.name;
                    let url = data.url;
                    http.download(url, { filename: filename, type: "application/octet-stream" });
                }
            }
        },
    }
};
</script>

<style scoped>
</style>
