<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline mr-8">
                    <el-select v-model="filters.supplierId" filterable clearable placeholder="分包单位" size="small" style="width: 300px;">
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-date-picker
                        v-model="filters.month"
                        type="month"
                        placeholder="结算周期"
                        value-format="yyyy-MM"
                        size="small">
                    </el-date-picker>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.isPaid" clearable placeholder="支付状态" size="small">
                        <el-option label="未支付" value="0"></el-option>
                        <el-option label="已支付" value="1"></el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
                <div v-if="auth.add" class="inline mr-8">
                    <el-button type="warning" size="small" @click="createMonthBill" :disabled="$store.state.org.type != 2">生成月度账单</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <!-- <div class="topbar">
                    <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleAdd(1)" :disabled="$store.state.org.type != 2">缴费</el-button>
                    </div>
                    <div class="inline mr-8">
                        <el-button type="warning" size="small" @click="handleAdd(2)" :disabled="$store.state.org.type != 2">退费</el-button>
                    </div>
                    <!- - <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div> - ->
                </div> -->
                <div class="datatable">
                    <el-table
                        ref="curBillTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="60">
                            <template slot-scope="scope">
                                <span>{{ (curPageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="paidNo"
                            label="单号"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="projectName"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="supplierName"
                            label="分包单位名称">
                        </el-table-column>
                        <el-table-column
                            prop="month"
                            label="结算周期"
                            width="80">
                        </el-table-column>
                        <el-table-column
                            prop="rent"
                            label="租金"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="fine"
                            label="罚款金额"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="cutPayment"
                            label="扣款金额"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="escalation"
                            label="调差金额"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="isPaid"
                            label="支付状态"
                            width="90">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.isPaid == 1" type="success" disable-transitions>已支付</el-tag>
                                <el-tag v-if="scope.row.isPaid == 0" type="danger" disable-transitions>未支付</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="paidMoney"
                            label="支付金额"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="paidTime"
                            label="支付日期"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            v-if="auth.edit || auth.print"
                            prop="action"
                            label="操作"
                            align="center"
                            width="200">
                            <template slot-scope="scope">
                                <el-button
                                    v-if="auth.edit && scope.row.isPaid != 1"
                                    size="mini"
                                    type="primary"
                                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button
                                    v-if="scope.row.isPaid == 1"
                                    size="mini"
                                    type="primary"
                                    @click="handleDetail(scope.$index, scope.row)">详情</el-button>
                                <el-button
                                    v-if="auth.print"
                                    size="mini"
                                    type="warning"
                                    @click="handlePrint(scope.$index, scope.row)">打印</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>
            <el-dialog
                title="生成月度账单"
                v-if="createBillDialog.create"
                :visible.sync="createBillDialog.visible"
                :close-on-click-modal="false"
                @closed="handleCreateBillClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <CreateBill @done="handleCreateBillDone"></CreateBill>
            </el-dialog>
            <el-dialog
                title="编辑"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <BillEdit :params="editDialog.params" @done="handleEditDone"></BillEdit>
            </el-dialog>
            <el-dialog
                title="详情"
                v-if="detailDialog.create"
                :visible.sync="detailDialog.visible"
                :close-on-click-modal="false"
                @closed="handleDetailClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <BillDetailEx :params="detailDialog.params" @done="handleDetailDone"></BillDetailEx>
            </el-dialog>
            <el-dialog
                title="打印"
                v-if="printDialog.create"
                :visible.sync="printDialog.visible"
                :close-on-click-modal="false"
                @closed="handlePrintClosed"
                :append-to-body="true"
                class="custom-dialog big-dialog custom-confirm"><!-- big-dialog small-dialog mini-dialog  -->
                <BillPrint ref="billPrint" :params="printDialog.params" @done="handlePrintDone"></BillPrint>
                <div slot="footer" class="custom-dialog-footer">
                    <span class="footer-inner">
                        <el-button @click="printDialog = { create: true, visible: false, params: {} }" size="small">取 消</el-button>
                        <el-button type="warning" @click="handlePrintConfirm" size="small">打 印</el-button>
                    </span>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import BillEdit from './BillEdit'
import BillDetailEx from './BillDetailEx'
import BillPrint from './BillPrint'
import CreateBill from './CreateBill'

export default {
    name: 'CostBill',
    components: {
        BillEdit,
        BillDetailEx,
        BillPrint,
        CreateBill,
    },
    data() {
        return {
            supplierList: [],

            filters: {
                supplierId: '',
                isPaid: '',
                month: '',
            },

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            curPageNum: 1,

            tableLoading: false,

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },

            detailDialog: {
                create: false,
                visible: false,
                params: {},
            },

            printDialog: {
                create: false,
                visible: false,
                params: {},
            },

            createBillDialog: {
                create: false,
                visible: false,
            },

            auth: {
                add: false,
                edit: false,
                print: false,
            },
        };
    },
    created () {
        this.loadTable();
        this.loadSupperList();

        this.auth.add = this.hasPermission('cost:bill:add');
        this.auth.edit = this.hasPermission('cost:bill:edit');
        this.auth.print = this.hasPermission('cost:bill:print');
    },
    methods: {
        ...common,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            params.projectId = this.$store.state.org.id;
            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/paid/page', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data.records;
                    data.forEach(item => {
                        item.rent = this.toFixed(item.rent, 2);
                        item.fine = this.toFixed(item.fine, 2);
                        item.cutPayment = this.toFixed(item.cutPayment, 2);
                        item.escalation = this.toFixed(item.escalation, 2);
                        if (item.isPaid == 1) {
                            item.paidMoney = this.toFixed(item.paidMoney, 2);
                            item.paidTime = (item.paidTime == null || item.paidTime == '') ? '-' : item.paidTime.substring(0, 10);
                        } else {
                            item.paidMoney = '-';
                            item.paidTime = '-';
                        }
                        // item.createTime = item.createTime.substring(0, 16);
                    });
                    this.tableData = data;
                    this.totalNum = req.data.data.total;
                    this.curPageNum = this.pageNum;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        // 编辑弹窗口
        handleEdit(index, row) {
            let params = {...row};
            this.editDialog = { create: true, visible: false, params: params };
            this.$nextTick(() => {
                this.editDialog = {  create: true, visible: true, params: params };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false, params: {} };
        },

        // 详情弹窗口
        handleDetail(index, row) {
            let params = {...row};
            this.detailDialog = { create: true, visible: false, params: params };
            this.$nextTick(() => {
                this.detailDialog = {  create: true, visible: true, params: params };
            });
        },
        handleDetailDone() {
            this.detailDialog = { create: true, visible: false, params: {} };
        },
        handleDetailClosed() {
            this.detailDialog = { create: false, visible: false, params: {} };
        },

        // 打印弹窗口
        handlePrint(index, row) {
            let params = {...row};
            this.printDialog = { create: true, visible: false, params: params };
            this.$nextTick(() => {
                this.printDialog = {  create: true, visible: true, params: params };
            });
        },
        handlePrintDone() {
            this.printDialog = { create: true, visible: false, params: {} };
        },
        handlePrintClosed() {
            this.printDialog = { create: false, visible: false, params: {} };
        },
        handlePrintConfirm() {
            this.$refs.billPrint.print();
        },

        // 生成月度账单
        createMonthBill() {
            this.createBillDialog = { create: true, visible: false, };
            this.$nextTick(() => {
                this.createBillDialog = {  create: true, visible: true, };
            });
        },
        handleCreateBillDone() {
            this.createBillDialog = { create: true, visible: false, };
            this.loadTable();
        },
        handleCreateBillClosed() {
            this.createBillDialog = { create: false, visible: false, };
        },
    }
}
</script>

<style scoped>
</style>
