<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="分包单位" prop="supplierId">
                        <el-select v-model="form.supplierId" placeholder="请选择" size="small" @change="handleSupplierChange">
                            <el-option
                                v-for="item in supplierList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="结算周期：" class="mix-item">
                        {{ data.month }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="罚款金额：" class="mix-item">
                        {{ data.fine }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="租金：" class="mix-item">
                        {{ data.rent }}
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="扣款金额：" class="mix-item">
                        {{ data.cutPayment }}
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="datatable-inner">
                <el-form-item label="" label-width="0" prop="paidDetailList">
                    <div class="datatable">
                        <el-table
                            ref="curBillTable"
                            :data="data.paidDetailList"
                            v-loading="tableLoading"
                            element-loading-background="rgba(255, 255, 255, 0.3)"
                            border
                            style="width: 100%">
                            <el-table-column
                                label="序号"
                                align="center"
                                width="80">
                                <template slot-scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="roomName"
                                label="宿舍编号"
                                min-width="100">
                                <template slot-scope="scope">
                                    <span :class="{ 'no-checkout': !scope.row.isCheckout }">{{ scope.row.roomName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="startDate"
                                label="开始日期"
                                min-width="120">
                            </el-table-column>
                            <el-table-column
                                prop="endDate"
                                label="结束日期"
                                min-width="120">
                            </el-table-column>
                            <el-table-column
                                prop="rent"
                                label="租金"
                                min-width="120">
                            </el-table-column>
                            <el-table-column
                                prop="rule"
                                label="费用规则"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.rule == 0">无效</span>
                                    <span v-if="scope.row.rule == 1">半月</span>
                                    <span v-if="scope.row.rule == 2">整月</span>
                                    <span v-if="scope.row.rule == 3">减免</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
            </div>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'CreateBill',
    data(){
        return {
            supplierList: [],
            data: {
                month: "-",
                rent: "-",
                fine: "-",
                cutPayment: "-",
                paidDetailList: [],
            },
            form: {
                supplierId: '',
                paidDetailList: [],
            },
            rules: {
                supplierId: [{required: true, message: '分包单位不能为空', trigger: 'change'}],
                paidDetailList: [
                    {required: true, message: '无租房信息，不能生成账单', trigger: 'change'},
                    {required: true, validator: this.checkCheckout, trigger: 'change'},
                ],
            },
            tableLoading: false,
            submitting: false,
        }
    },
    created() {
        this.loadSupperList();
    },
    methods: {
        ...common,
        ...verify,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSupplierChange() {
            this.loadBillData();
        },
        loadBillData() {
            let params = {};
            params.supplierId = this.form.supplierId;
            this.tableLoading = true;
            http.get("v1/paid/bill/info", { params: params }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    if (!data) {
                        this.data = {
                            month: "-",
                            rent: "-",
                            fine: "-",
                            cutPayment: "-",
                            paidDetailList: [],
                        };
                        this.form.paidDetailList = [];
                        this.$refs.form.validateField("paidDetailList");
                        return;
                    }

                    data.paidDetailList.forEach(x => {
                        x.rent = this.toFixed(x.rent, 2);
                    });
                    this.form.paidDetailList = data.paidDetailList;
                    this.data = data;
                    this.$refs.form.validateField("paidDetailList");
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {};
                    data.supplierId = this.form.supplierId;

                    http.post('v1/paid/create/bill', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('提交成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        checkCheckout (rule, value, callback) {
            for(let i = 0; i < this.form.paidDetailList.length; i++) {
                let paidDetail = this.form.paidDetailList[i];
                if (!paidDetail.isCheckout) {
                    callback(new Error('存在租用房间，不能生成账单'));
                    return;
                }
            }

            callback();
        },
        resetForm() {
            this.form.supplierId = '';
            this.form.paidDetailList = [];
            this.data = {
                month: "-",
                rent: "-",
                fine: "-",
                cutPayment: "-",
                paidDetailList: [],
            };
            this.$nextTick(() => {
                this.$refs.form.clearValidate("supplierId");
                this.$refs.form.clearValidate("paidDetailList");
            });
        }
    }
};
</script>

<style scoped>
.datatable-inner {
    margin-left:-11px;
    margin-right:-11px;
}
.no-checkout {
    color: #e00;
}
.datatable-inner /deep/ .el-form-item__error {
    margin-left: 25px;
}
</style>
