<template>
    <div class="dialog-inner" style="padding:0;">
        <div class="page-sep">No.1</div>
        <div class="print-page">
            <h2 class="page-title">月度账单</h2>
            <div class="page-head clearfix">
                <div class="page-no">账单编号：{{ detail.paidNo }}</div>
                <div class="page-month">结算周期：{{ detail.month }}</div>
            </div>
            <div class="page-table-box">
                <table border="0" cellpadding="0" cellspacing="0" class="page-table">
                    <tr>
                        <td class="col-t">项目名称</td>
                        <td class="col-c">{{ detail.projectName }}</td>
                        <td class="col-t">分包单位</td>
                        <td class="col-c">{{ detail.supplierName }}</td>
                    </tr>
                    <tr>
                        <td class="col-t">租金</td>
                        <td class="col-c"><span v-if="detail.rent != ''">&yen;</span>{{ detail.rent }}</td>
                        <td class="col-t">罚款金额</td>
                        <td class="col-c"><span v-if="detail.fine != ''">&yen;</span>{{ detail.fine }}</td>
                    </tr>
                    <tr>
                        <td class="col-t">调差金额</td>
                        <td class="col-c"><span v-if="detail.escalation != ''">&yen;</span>{{ detail.escalation }}</td>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="col-t">总金额</td>
                        <td class="col-c"><span v-if="detail.total != ''">&yen;</span>{{ detail.total }}</td>
                        <td class="col-t">大写</td>
                        <td class="col-c">{{ detail.totalText }}</td>
                    </tr>
                </table>
            </div>
            <div class="page-sign clearfix">
                <div class="page-sign-1">打印时间：{{ curDate }}</div>
                <div class="page-sign-2">打印人：{{ userName }}</div>
                <div class="page-sign-3">签字确认：<span class="gap"></span></div>
            </div>
        </div>
        <div v-for="(page, index) in pageList" :key="index">
            <div class="page-sep">No.{{ index + 2 }}</div>
            <div class="print-break"></div>
            <div v-if="page.type == 1" class="print-page">
                <h2 class="page-title">租金明细</h2>
                <div class="page-head clearfix">
                    <div class="page-no">账单编号：{{ detail.paidNo }}</div>
                    <div class="page-month">结算周期：{{ detail.month }}</div>
                </div>
                <div class="page-table-box">
                    <table border="0" cellpadding="0" cellspacing="0" class="page-table">
                        <tr>
                            <td class="col1-t col1-no">序号</td>
                            <td class="col1-t">宿舍编号</td>
                            <td class="col1-t">开始日期</td>
                            <td class="col1-t">结束日期</td>
                            <td class="col1-t">租金</td>
                        </tr>
                        <tr v-for="item in page.list" :key="item.id">
                            <td class="col1-c">{{ item.index }}</td>
                            <td class="col1-c">{{ item.roomName }}</td>
                            <td class="col1-c">{{ item.startDate }}</td>
                            <td class="col1-c">{{ item.endDate }}</td>
                            <td class="col1-c">&yen;{{ item.rent }}</td>
                        </tr>
                    </table>
                </div>
                <div class="page-sign clearfix">
                    <div class="page-sign-1">打印时间：{{ curDate }}</div>
                    <div class="page-sign-2">打印人：{{ userName }}</div>
                    <div class="page-sign-3">签字确认：<span class="gap"></span></div>
                </div>
            </div>
            <div v-if="page.type == 2" class="print-page">
                <h2 class="page-title">罚款明细</h2>
                <div class="page-head clearfix">
                    <div class="page-no">账单编号：{{ detail.paidNo }}</div>
                    <div class="page-month">结算周期：{{ detail.month }}</div>
                </div>
                <div class="page-table-box">
                    <table border="0" cellpadding="0" cellspacing="0" class="page-table">
                        <tr>
                            <td class="col2-t col2-no">序号</td>
                            <td class="col2-t">罚款日期</td>
                            <td class="col2-t">罚款金额</td>
                        </tr>
                        <tr v-for="item in page.list" :key="item.id">
                            <td class="col2-c">{{ item.index }}</td>
                            <td class="col2-c">{{ item.day }}</td>
                            <td class="col2-c">&yen;{{ item.revenue }}</td>
                        </tr>
                    </table>
                </div>
                <div class="page-sign clearfix">
                    <div class="page-sign-1">打印时间：{{ curDate }}</div>
                    <div class="page-sign-2">打印人：{{ userName }}</div>
                    <div class="page-sign-3">签字确认：<span class="gap"></span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import BigDecimal from 'js-big-decimal'

export default {
    name: 'CostBillPrint',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            detail: {
                paidNo: '',
                month: '',
                projectName: '',
                supplierName: '',
                rent: '',
                fine: '',
                escalation: '',
                total: '',
                totalText: '',
            },
            curDate: '',
            userName: '',
            rentList: [],
            fineList: [],
            pageRowCount: 30,
            loadCount: 0,
            pageList: [],
        }
    },
    created() {
        this.init();
        this.loadCount = 0;
        this.loadRentList();
        this.loadFineList();
    },
    methods: {
        ...common,
        ...verify,
        init() {
            let detail = {}
            detail.paidNo = this.params.paidNo;
            detail.month = this.getMonthText(this.params.month);
            detail.projectName = this.params.projectName;
            detail.supplierName = this.params.supplierName;
            detail.rent = this.params.rent;
            detail.fine = this.params.fine;
            detail.escalation = this.params.escalation;

            let rent = new BigDecimal(detail.rent);
            let fine =  new BigDecimal(detail.fine);
            let escalation =  new BigDecimal(detail.escalation);
            let total = rent.add(fine).add(escalation).round(2, BigDecimal.RoundingModes.CEILING);

            detail.total = total.getValue();

            this.detail = detail;

            let maxCurrency = new BigDecimal('9999999999999999.9999');
            if (total.compareTo(maxCurrency) <= 0) {
                detail.totalText = this.convertCurrency(detail.total);
            } else {
                this.showError('总金额数值过大');
            }

            this.curDate = this.getLocalTime();
            
            let userInfo = this.getUserInfo();
            this.userName = userInfo.name;
        },
        // 加载租金列表
        loadRentList() {
            let params = {};
            params.id = this.params.id;
            http.get('v1/paid/detail/list', {
                params: params
            }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    this.rentList = data;
                    this.loadCount++;
                    this.loadDone();
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        // 加载罚款列表
        loadFineList() {
            let params = {};
            params.supplierId = this.params.supplierId;
            params.startDate = this.params.month+'-01';
            params.endDate = this.getLastDate(params.startDate);
            http.get('v1/punish/list', {
                params: params
            }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    this.fineList = data;
                    this.loadCount++;
                    this.loadDone();
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadDone() {
            if (this.loadCount < 2) {
                return;
            }

            let pageRowCount = this.pageRowCount;
            let pageList = [];
            let index = 0;
            let page;
            this.rentList.forEach(item => {
                index++;

                if (index % pageRowCount == 1) {
                    page = {type: 1, list: []};
                    pageList.push(page);
                }

                page.list.push({
                    index: index,
                    roomName: item.roomName,
                    startDate: this.getDateText(item.startDate),
                    endDate: this.getDateText(item.endDate),
                    rent: this.toFixed(item.rent, 2),
                });
            });

            index = 0;
            this.fineList.forEach(item => {
                index++;

                if (index % pageRowCount == 1) {
                    page = {type: 2, list: []};
                    pageList.push(page);
                }

                page.list.push({
                    index: index,
                    revenue: this.toFixed(item.revenue, 2),
                    day: this.getDateText(item.day),
                });
            });

            this.pageList = pageList;
        },
        print() {
            window.print();
        },
    }
};
</script>

<style scoped>
</style>
