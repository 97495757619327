<template>
    <div class="dialog-inner" style="padding:0;">
        <el-row :gutter="20">
            <el-col :span="8">
                <div class="data-item">
                    <div class="label">项目名称：</div>
                    <div class="text">{{ params.projectName }}</div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="data-item">
                    <div class="label">分包单位名称：</div>
                    <div class="text">{{ params.supplierName }}</div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="data-item">
                    <div class="label">结算周期：</div>
                    <div class="text">{{ params.month }}</div>
                </div>
            </el-col>
        </el-row>
        <div class="datatable-inner">
            <div class="datatable">
                <el-table
                    ref="curBillTable"
                    :data="tableData"
                    v-loading="tableLoading"
                    element-loading-background="rgba(255, 255, 255, 0.3)"
                    border
                    style="width: 100%">
                    <el-table-column
                        label="序号"
                        align="center"
                        width="80">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="revenue"
                        label="罚款金额"
                        min-width="120">
                    </el-table-column>
                    <el-table-column
                        prop="day"
                        label="罚款日期"
                        min-width="120">
                    </el-table-column>
                    <el-table-column
                        prop="remark"
                        label="备注"
                        min-width="120">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'CostBillFine',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            tableData: [],
        }
    },
    created() {
        this.init();
    },
    methods: {
        ...common,
        ...verify,
        init() {
            this.loadTable();
        },
        // 加载表格数据
        loadTable() {
            let params = {};
            params.supplierId = this.params.supplierId;
            params.startDate = this.params.month+'-01';
            params.endDate = this.getLastDate(params.startDate);
            this.tableLoading = true;
            http.get('v1/punish/list', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    data.forEach(item => {
                        item.revenue = this.toFixed(item.revenue, 2);
                    });
                    this.tableData = data;
                    this.$emit("change", this.tableData);
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
    }
};
</script>

<style scoped>
</style>
